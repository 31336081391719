.PixelRow {
    margin-bottom: 1px;
}

.Pixel {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    margin: 1px;
    box-sizing: border-box;
}

.Pixel p {
    margin: 0;
}